var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.syncedFilter),callback:function ($$v) {_vm.syncedFilter=$$v},expression:"syncedFilter"}}),_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"item-key":"id","hide-default-footer":"","page":_vm.syncedPage,"items-per-page":_vm.syncedItemsPerPage,"server-items-length":_vm.serverItemsLength},on:{"update:page":function($event){_vm.syncedPage=$event}},scopedSlots:_vm._u([(_vm.options.link && _vm.libraryId)?{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"template-link",attrs:{"to":{
          name: 'template',
          params: {
            libraryId: _vm.libraryId,
            templateId: item.id
          }
        }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners)),_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":"20"},model:{value:(_vm.syncedPage),callback:function ($$v) {_vm.syncedPage=$$v},expression:"syncedPage"}}),_c('v-select',{staticClass:"itemsPerPage",attrs:{"label":"Templates per page:","items":[10, 20, 50]},model:{value:(_vm.syncedItemsPerPage),callback:function ($$v) {_vm.syncedItemsPerPage=$$v},expression:"syncedItemsPerPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }