





















































import {
  Vue,
  Component,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import { TableHeader } from '@/components/layout/models/table.d';
import { TableOptions } from '@/models/form';

import config from '@/config';

@Component
export default class TemplateTable extends Vue {
  @Prop({ default: () => ({}) }) options!: TableOptions;

  @Prop() libraryId!: string;

  @Prop({ default: 0 }) serverItemsLength!: number;

  @PropSync('itemsPerPage', { default: () => config.defaultPageSize }) syncedItemsPerPage!: number;

  @PropSync('page', { default: 1 }) syncedPage!: number;

  @PropSync('filter', { default: '' }) syncedFilter!: string;

  headers: TableHeader[] = [];

  get numberOfPages() {
    if (this.syncedItemsPerPage === 0) return 1;
    return Math.max(1, Math.ceil(this.serverItemsLength / this.syncedItemsPerPage));
  }

  mounted() {
    this.headers = [
      {
        sortable: true,
        sortDirection: '',
        text: 'Template Name',
        value: 'name',
        width: '45%',
      },
      {
        sortable: true,
        sortDirection: '',
        text: 'Description',
        value: 'description',
        width: '45%',
      },
      {
        sortable: false,
        text: 'Number of Rules',
        value: 'number-of-rules',
        width: '10%'
      },
    ];
  }
}
