














































import TemplateTable from "@/components/library-maintenance/template-table.vue";
import { Component, Vue, Watch, Inject } from "vue-property-decorator";
import Card from "@/components/material/Card.vue";
import { SnackbarOptions, TableOptions, VDataTableOptions } from "@/models/form";
import EcSnackBar from "common-components/src/components/form/ec-snack-bar.vue";
import TemplateService from "@/services/template-service";
import { backOr } from "@/router";
import { getModule } from "vuex-module-decorators";
import LocalState from "@/store/modules/local-state-module";
import { Library, Template } from "@/models/library-maintenance.d";
import { Dictionary } from "vue-router/types/router.d";
import debounce from "lodash.debounce";
import LibraryService from "@/services/library-service";
import axios, { CancelTokenSource } from "axios";

const localState = getModule(LocalState);

@Component({
  components: {
    Card,
    TemplateTable,
    EcSnackBar
  }
})
export default class TemplateListView extends Vue {
  @Inject() TemplateService!: TemplateService;

  @Inject() LibraryService!: LibraryService;

  options: TableOptions = {
    link: true
  };

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

  libraryId = "";

  library: Library = {} as Library;

  libraryTitle = "";

  loading = false;

  items: Template[] = [];

  totalItems = 0;

  page = 1;

  size = localState.defaultPageSize;

  sort: string[] = ["name,asc"];

  filter: string | null = null;

  cancellationToken!: CancelTokenSource;

  get sortBy() {
    return this.sort.map(x => (x ? x.split(",")[0] : ""));
  }

  get sortDesc() {
    return this.sort.map(x => (x ? x.split(",")[1] === "desc" : false));
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    const { page = 1, size = localState.defaultPageSize, sort = ["name,asc"], filter = "" } =
      this.$route.query || {};

    this.libraryId = this.$route.params.libraryId;

    this.LibraryService.readSingle(this.libraryId).then(library => {
      this.library = library;
      this.libraryTitle = `Templates of ${this.library.name}`;
    });

    this.page = +page;
    this.size = +size;
    this.sort = [sort].flat().map(x => x ?? "");
    this.filter = filter ? `${filter}` : null;

    this.loadPage();
  }

  private debounceFilter?: (arg: string) => void;

  onFilterChange(newFilter: string) {
    if (!this.debounceFilter) {
      this.debounceFilter = debounce((filter: string | null) => {
        const currentQuery = this.$route.query;
        const { filter: currentFilter } = currentQuery;

        if ((!currentFilter && !filter) || currentFilter === filter) return;

        this.$router.replace({
          query: {
            ...currentQuery,
            page: "1",
            filter: filter || undefined
          }
        });
      }, 500);
    } else {
      this.debounceFilter(newFilter);
    }
  }

  optionsUpdated(options: VDataTableOptions) {
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const currentQuery = this.$route.query;

    const sort = sortBy.map((by, index) => `${by},${sortDesc[index] ? "desc" : "asc"}`);
    currentQuery.sort = [currentQuery.sort].flat();

    const newQuery: Dictionary<string | string[] | null> = {
      page: `${page}`,
      size: `${itemsPerPage}`,
      sort,
      filter: this.filter
    };
    if (sort.length === 0) {
      delete newQuery.sort;
    }
    if (this.filter?.length === 0) {
      delete newQuery.filter;
    }

    if (JSON.stringify(currentQuery) === JSON.stringify(newQuery)) return;

    this.$router.replace({
      query: newQuery
    });
  }

  pageUpdated(page: number) {
    const currentQuery = this.$route.query;
    currentQuery.sort = [currentQuery.sort].flat();

    if (currentQuery.page === `${page}`) {
      return;
    }

    this.$router.replace({
      query: {
        ...currentQuery,
        page: `${page}`
      }
    });
  }

  async loadPage() {
    if (!this.libraryId) return;

    const pagination = {
      page: this.page,
      size: this.size,
      sort: this.sort
    };

    this.loading = true;

    try {
      if (this.cancellationToken) this.cancellationToken.cancel("Reloaded list");
      this.cancellationToken = axios.CancelToken.source();

      const list = await this.TemplateService.listTemplates(
        this.libraryId,
        pagination,
        this.filter || undefined,
        this.cancellationToken.token
      );

      this.size = list.page.size;
      this.page = list.page.number;
      this.totalItems = list.page.totalElements;
      this.items = list._embedded.templates;

      localState.setDefaultPageSize(this.size);
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
      }
    } finally {
      this.loading = false;
    }
  }

  back() {
    backOr({ name: "libraries" });
  }
}
